import {
  ref, getCurrentInstance,
} from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function roleFeature() {
  const { proxy } = getCurrentInstance()
  // 权限弹框操作
  const isShowMenu = ref(true)
  const menuItems = ref([])
  const roleNameModel = ref('')
  const descriptionModel = ref('')
  const newRoleTitle = ref('')
  const currentRoleId = ref(null)
  const newFeatureList = ref([])
  const addRoleLoading = ref(false)
  // 从接口拿回数据，处理成父子两层
  // 1：EDIT 2：VIEW
  const updateMenuItems = features => {
    // Download不需要单独授权，只要有可以下载数据页面的权限就有Download权限
    const parentNode = features.filter(value => value.parent_feature_id === '' && value.feature_id !== 'download')
    parentNode.map(value => {
      if (value.perm === 1) {
        // eslint-disable-next-line no-param-reassign
        value.isSelected = `${value.feature_id}-edit`
      } else if (value.perm === 2) {
        // eslint-disable-next-line no-param-reassign
        value.isSelected = `${value.feature_id}-view`
      } else {
        // eslint-disable-next-line no-param-reassign
        value.isSelected = false
      }
      return value
    })
    const newFeature = features.reduce((prev, next) => {
      if (next.parent_feature_id !== '') {
        const index = prev.findIndex(feature => feature.feature_id === next.parent_feature_id)
        if (!prev[index].children) {
          // eslint-disable-next-line no-param-reassign
          prev[index].children = []
        }
        if (next.perm === 1) {
          // eslint-disable-next-line no-param-reassign
          next.isSelected = `${next.feature_id}-edit`
        } else if (next.perm === 2) {
          // eslint-disable-next-line no-param-reassign
          next.isSelected = `${next.feature_id}-view`
        } else {
          // eslint-disable-next-line no-param-reassign
          next.isSelected = false
        }
        // eslint-disable-next-line no-unused-expressions
        const isExist = prev[index].children.some(value => value.feature_id === next.feature_id)
        if (!isExist) {
          prev[index].children.push(next)
        }
      }
      return prev
    }, parentNode)
    return newFeature
  }
  // $store.state.wallet.navMenuItems
  const getMenuItems = async () => {
    addRoleLoading.value = true
    const response = await proxy.$api.fetchFeatureList()
    const { code, data, message } = response.data
    addRoleLoading.value = false
    if (code === 200) {
      const { list } = data
      menuItems.value = updateMenuItems(list)
    } else {
      proxy.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notification',
          icon: 'BellIcon',
          text: `${message}`,
          variant: 'danger',
        },
      }, {
        position: 'top-center',
      })
    }
  }
  const editAll = (value, index) => {
    const menuItem = menuItems.value[index]
    const menuItemsChild = menuItem.children
    if (menuItemsChild && menuItemsChild.length > 0) {
      isShowMenu.value = false
      menuItemsChild.map(childValue => {
        const newValue = childValue
        if (menuItem.isSelected) {
          newValue.isSelected = `${childValue.feature_id}-edit`
        } else {
          newValue.isSelected = false
        }
        return newValue
      })
      isShowMenu.value = true
    }
  }
  const viewAll = (value, index) => {
    const menuItem = menuItems.value[index]
    const menuItemsChild = menuItem.children
    if (menuItemsChild && menuItemsChild.length > 0) {
      isShowMenu.value = false
      menuItemsChild.map(childValue => {
        const newValue = childValue
        if (menuItem.isSelected) {
          newValue.isSelected = `${childValue.feature_id}-view`
        } else {
          newValue.isSelected = false
        }
        return newValue
      })
      isShowMenu.value = true
    }
  }
  const childEditAll = (value, index) => {
    const menuItem = menuItems.value[index]
    const menuItemChild = menuItem.children
    // 选中校验是否宣布选中，让父元素选中
    if (menuItemChild) {
      isShowMenu.value = false
      const isSelectedAll = menuItemChild.reduce((prev, cur) => {
        if (typeof cur.isSelected === 'string') {
          return prev && cur.isSelected.split('-')[1] === 'edit'
        }
        return prev && cur.isSelected
      }, true)
      if (isSelectedAll) {
        menuItem.isSelected = `${menuItem.feature_id}-edit`
      } else {
        menuItem.isSelected = false
      }
      isShowMenu.value = true
    }
  }
  const childViewAll = (value, index) => {
    const menuItem = menuItems.value[index]
    const menuItemChild = menuItem.children
    // 选中校验是否宣布选中，让父元素选中
    if (menuItemChild) {
      isShowMenu.value = false
      const isSelectedAll = menuItemChild.reduce((prev, cur) => {
        if (typeof cur.isSelected === 'string') {
          return prev && cur.isSelected.split('-')[1] === 'view'
        }
        return prev && cur.isSelected
      }, true)
      if (isSelectedAll) {
        menuItem.isSelected = `${menuItem.feature_id}-view`
      } else {
        menuItem.isSelected = false
      }
      isShowMenu.value = true
    }
  }
  const featureListData = items => {
    items.forEach(f => {
      if (f.isSelected) {
        // 1：EDIT 2：VIEW
        if (f.isSelected.includes('edit')) {
          newFeatureList.value.push({ feature_id: f.feature_id, perm: 1 })
        } else if (f.isSelected.includes('view')) {
          newFeatureList.value.push({ feature_id: f.feature_id, perm: 2 })
        }
      }
      if (f.children) {
        featureListData(f.children)
      }
    })
    return newFeatureList.value
  }
  const getRoleDetail = async ({ role_id }) => {
    const response = await proxy.$api.fetchRoleDetail({ role_id })
    const { code, data } = response.data
    if (code === 200) {
      const { feature_list, role_meta } = data
      return { feature_list, role_meta }
    }
    return {}
  }
  const editMenuItems = async row => {
    // 选中选项
    addRoleLoading.value = true
    const { feature_list, role_meta } = await getRoleDetail(row)
    roleNameModel.value = role_meta.role_name
    descriptionModel.value = role_meta.description
    const { data } = await proxy.$api.fetchFeatureList()
    const { code, data: { list } } = data
    addRoleLoading.value = false
    if (code === 200) {
      const featureListJson = list.map(feature => {
        const featureItem = feature_list.filter(value => value.feature_id === feature.feature_id)[0]
        return Object.assign(feature, featureItem)
      })
      menuItems.value = updateMenuItems(featureListJson)
    }
  }
  const saveNewRoleFunc = (params, callback) => {
    if (params.feature_list.length === 0) {
      proxy.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notification',
          icon: 'BellIcon',
          text: '👋 Please choice at least one feature',
          variant: 'danger',
        },
      }, {
        position: 'top-center',
      })
      return
    }
    proxy.$refs.addRoleForm.validate().then(async success => {
      if (success) {
        const res = await proxy.$api.saveRole(params)
        if (res.data.code === 200) {
          proxy.$refs.newRoleModel.hide()
          callback()
          proxy.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: 'Success',
              variant: 'success',
            },
          }, {
            position: 'top-center',
          })
        } else {
          proxy.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: `${res.data.message}`,
              variant: 'danger',
            },
          }, {
            position: 'top-center',
          })
        }
      }
    })
  }

  return {
    isShowMenu,
    menuItems,
    roleNameModel,
    descriptionModel,
    newRoleTitle,
    currentRoleId,
    getMenuItems,
    editMenuItems,
    newFeatureList,
    addRoleLoading,
    // function
    updateMenuItems,
    editAll,
    viewAll,
    childEditAll,
    childViewAll,
    featureListData,
    getRoleDetail,
    saveNewRoleFunc,
  }
}
